<template>
  <div class="mb-10 flex flex-col justify-center items-center">
    <HeaderGlobal
      :titlemenu1="this.titlemenu1"
      :titlemenu2="this.titlemenu2"
      :titlemenu3="this.titlemenu3"
    />
    <div class="mb-10 mt-20 w-11/12 md:w-10/12">
      <div
        class="mb-2 flex flex-col justify-center items-center"
        v-if="!editable"
      >
        <img
          class="mb-2 rounded-full w-24 h-24"
          rel="prefetch"
          :src="imageProfile"
          alt="profile picture"
        />
        <!-- <h5
          class="
            mt-2
            font-proximaMedium
            text-mediumGrey text-xs text-center
            cursor-pointer
          "
          @click="editAccount"
        >
          {{ $t("global_edit_profile_picture") }}
        </h5> -->
      </div>
      <InputImage
        :image="imageProfile"
        @blurred="inputImg"
        :title="$t('global_edit_profile_picture')"
        v-else
      />
      <h1 class="text-xl font-proximaMedium" v-if="!editable">
        {{ $t("account_title") }}
      </h1>
      <h1 class="text-xl font-proximaMedium" v-else>
        {{ $t("edit_account_title") }}
      </h1>
      <FormError :errors="errorForm" />
      <AccountForms class="mt-8" :data="user" ref="child" />
      <div class="border-t border-lightGrey20 mt-6" v-if="!editable">
        <SubmitButton
          :title="$t('account_edit_button')"
          background="bg-primary"
          @click.native="editAccount"
        />
        <BorderedButton
          :title="$t('account_delete_button')"
          borderColor="border-red"
          textCl="text-red"
          @click.native="showModal = true"
        />
      </div>
      <div class="border-t border-lightGrey20 mt-6" v-else>
        <Loader class="mt-2 flex justify-center" v-if="btnLoader" />
        <div class="flex flex-col-reverse lg:flex-row justify-between" v-else>
          <BorderedButton
            class="lg:mr-1"
            :title="$t('global_cancel')"
            borderColor="border-mediumGrey"
            textCl="text-darkGrey"
            @click.native="editAccount"
          />
          <SubmitButton
            class="lg:ml-1"
            :title="$t('account_save_button')"
            background="bg-primary"
            @click.native="saveeditAccount"
          />
        </div>
      </div>
    </div>
    <Modals :show="showModal" @close="showModal = false">
      <template #header>
        <h3>custom header</h3>
      </template>
    </Modals>
  </div>
</template>

<script>
import AccountForms from "./AccountForms.vue";
import InputImage from "../forms/InputImage.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import Modals from "../modals/Modals.vue";
import HeaderGlobal from "../header/HeaderGlobal.vue";
import { updateUser } from "../../services/userService";
import FormError from "../warning/FormError.vue";
import { mapGetters, mapActions } from "vuex";
import Loader from "../loader/Loader.vue";

export default {
  components: {
    AccountForms,
    InputImage,
    SubmitButton,
    BorderedButton,
    Modals,
    HeaderGlobal,
    FormError,
    Loader,
  },
  data() {
    return {
      image: null,
      showModal: false,
      titlemenu1: this.$t("account_dropdown_menu_settings"),
      titlemenu2: "Account Info",
      titlemenu3: "",
      btnLoader: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      resUser: "auth/resUser",
    }),
    errorForm() {
      return this.$store.state.errorForm;
    },
    editable() {
      return this.$store.state.editable;
    },
    imageProfile() {
      return this.user
        ? this.user.profileImage
          ? this.user.profileImage
          : require(`@/assets/images/profilepic-icon.png`)
        : null;
    },
    closeEditOnSave() {
      if (this.resUser == 200) {
        return false;
      }
    },
  },

  created() {
    const req = {
      email: localStorage.getItem("email"),
      role: localStorage.getItem("role"),
    };
    this.getUser(req);
  },
  watch: {
    resUser(i) {
      console.log("//////////////", i);
      if (i.status == 200) {
        this.btnLoader = false;
        this.$store.commit("editable", false);
      }
    },
  },
  methods: {
    ...mapActions({
      getUser: "auth/getUser",
    }),
    inputImg(val) {
      console.log("******", val);
      this.image = val;
      //const account = this.$route.params.data;
      //account.profileImage = val;
      //this.$store.commit("accountState", account);
    },
    editAccount() {
      this.$store.commit("editable", !this.$store.state.editable);
      this.titlemenu1 = this.$t("account_dropdown_menu_settings");
      this.titlemenu2 = "Account Info";

      if (this.$store.state.editable) {
        this.titlemenu3 = this.$t("edit_account_title");
      } else {
        this.titlemenu3 = "";
        //this.$store.commit("accountState", this.user);
        this.$refs.child.cancelForm(this.user);
        //this.$store.state.accountState.profileImage
      }
    },
    async saveeditAccount() {
      this.titlemenu1 = this.$t("account_dropdown_menu_settings");
      this.titlemenu2 = "Account Info";
      this.titlemenu3 = "";
      //this.btnLoader = true;

      this.$refs.child.submitForm(this.image);
      //await updateUser(localStorage.getItem("email"), 1, data, imageProfile);
    },
  },
};
</script>

<style></style>
