<template>
  <div v-if="account">
    <FormError :errors="errors.save" />
    <div class="flex justify-between">
      <Input
        :label="$t('global_firstname')"
        :editable="editable"
        v-model="account.firstName"
        classAdd="w-full mr-1 flex flex-col justify-start items-start relative"
        :error="errors.firstName"
      />
      <Input
        :label="$t('global_lastname')"
        :editable="editable"
        v-model="account.lastName"
        classAdd="w-full ml-1 flex flex-col justify-start items-start relative"
        :error="errors.lastName"
      />
    </div>
    <Input
      :label="$t('global_email')"
      :editable="false"
      v-model="account.emailAddress"
      classAdd="mt-5 flex flex-col justify-start items-start relative"
    />

    <div>
      <FormError :errors="errors.phoneNo" />

      <div class="flex flex-col justify-start items-start relative mb-1">
        <label class="text-sm font-proximaMedium">{{
          $t("global_phone_number")
        }}</label>
        <vue-tel-input
          required
          :disabled="!editable"
          :inputOptions="options"
          v-model="phone"
          validCharactersOnly
          mode="international"
          @input="handleInput"
          class="rounded-md"
          :class="{
            'border border-red': errors.phoneNo,
            'border-0': !errors.phoneNo,
          }"
        />
      </div>
    </div>

    <Input
      v-if="(roleComp == 3)"
      :label="$t('global_street_address')"
      :editable="editable"
      v-model="account.kennelAddress"
      class="mt-5 flex flex-col justify-start items-start relative"
      :error="errors.kennelAddress"
    />
    <Input
      v-else
      :label="$t('global_street_address')"
      :editable="editable"
      v-model="account.streetAddress"
      class="mt-5 flex flex-col justify-start items-start relative"
      :error="errors.streetAddress"
    />

    <div class="mt-5 flex justify-between">
      <div class="w-full mr-1 flex flex-col justify-start items-start relative">
        <label class="text-sm font-proximaMedium" for="country">{{
          $t("global_country")
        }}</label>
        <!--<div
          v-if="!editable"
          class="w-full bg-greyishWhite text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
        >
          {{ account.country.name }}
        </div> -->

        <select
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          :class="{
            'bg-greyishWhite': !editable,
          }"
          v-model="account.country"
          :disabled="!editable"
        >
          <option value="" disabled selected>
            {{ $t("global_select_options") }}
          </option>
          <option v-for="item in countriesList" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
        <img
          v-if="editable"
          class="absolute right-4 top-8"
          rel="prefetch"
          src="@/assets/images/edit-icon.png"
          alt="edit-icon"
        />

        <FormError :errors="errors.country" />
      </div>
      <div class="w-full ml-1 flex flex-col justify-start items-start">
        <Input
          :label="$t('global_postal_code')"
          :editable="editable"
          v-model="account.postalCode"
          classAdd="w-full ml-1 flex flex-col justify-start items-start relative"
          :error="errors.postalCode"
        />
      </div>
    </div>

    <!-- ----------------------------------------- Begin role == 3 -------------------------------------------->

    <div v-if="roleComp == 3" class="mt-5 flex justify-between">
      <div class="w-full mr-1 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="kennelClub">{{
          $t("after_verification_kannel")
        }}</label>
        <!--  <div
          v-if="!editable"
          class="w-full bg-greyishWhite text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
        >
          {{ account.kennelClub.name }}
        </div> -->
        <div class="w-full relative">
          <select
            class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
            :class="{
              'bg-greyishWhite': !editable,
            }"
            v-model="account.kennelClub"
            :disabled="!editable"
          >
            <option value="" disabled selected>
              {{ $t("global_select_options") }}
            </option>
            <option
              v-for="item in sortedKennelClubs"
              :value="item.id"
              :key="item.id"
            >
              {{ item.name }}
            </option>
          </select>
          <img
            v-if="editable"
            class="absolute right-4 top-4"
            rel="prefetch"
            src="@/assets/images/edit-icon.png"
            alt="edit-icon"
          />
          <FormError :errors="errors.kennelClub" />
        </div>
      </div>

      <Input
        type="date"
        :label="$t('global_registration_date')"
        :editable="editable"
        v-model="account.kennelFoundationDate"
        classAdd="w-full ml-1 flex flex-col justify-start items-start relative"
        :error="errors.kennelFoundationDate"
      />

      <FormError :errors="errors.kennelFoundationDate" />
    </div>

    <Input
      v-if="roleComp == 3"
      :label="$t('after_verification_kannel_registration_name')"
      :editable="editable"
      v-model="account.kennelName"
      classAdd="mt-5 flex flex-col justify-start items-start relative"
      :error="errors.kennelName"
    />
    <Input
      v-if="roleComp == 3"
      :label="$t('after_verification_kannel_registration_number')"
      :editable="editable"
      v-model="account.kennelRegistrationNo"
      classAdd="mt-5 flex flex-col justify-start items-start relative"
      :error="errors.kennelRegistrationNo"
    />
    <!----------------------------------------- End role == 3 ----------------------------------------------->
  </div>
</template>

<script>
import InputSelect from "../forms/InputSelect.vue";
import Input from "../forms/Input_.vue";
import Select from "../forms/Select.vue";
import "vue-tel-input/dist/vue-tel-input.css";
import { VueTelInput } from "vue-tel-input";
import FormError from "../warning/FormError.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { getCountries } from "../../services/countriesServices";
import { getStates } from "../../services/stateServices";
import { getKennelClubs } from "../../services/dogBreedersService";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    InputSelect,
    Input,
    Select,
    VueTelInput,
    FormError,
    DatePicker,
  },
  data() {
    return {
      //editable: this.editableProps,
      role: localStorage.getItem("role"),
      phone: "",
      countryCode: "",
      errors: {},
      countriesList: [],
      statesList: [],
      editPhoneNumber: "", // edited phone number to submit
      options: {
        placeholder: this.$t("global_phone_number_placeholder"),
      },
      coordinates: { lng: 3.05997, lat: 36.7762 },
      kennelClubsList: [],
    };
  },
  computed: {
    ...mapGetters({
      account: "auth/user",
    }),

    roleComp() {
      return localStorage.getItem("role");
    },
    sortedKennelClubs: function () {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      return this.kennelClubsList.sort(compare);
    },
    editable() {
      return this.$store.state.editable;
    },
  },
  async created() {
    this.role = localStorage.getItem("role");

    console.log(this.account);

    const req = {
      email: localStorage.getItem("email"),
      role: localStorage.getItem("role"),
    };
    this.getUser(req);

    const a = await getCountries();
    this.countriesList = a.data;

    /* if (this.account.country) {
      const c = await getStates(this.account.country.id);
      this.statesList = c.data;
    }*/
    this.phone = this.account ? this.account.phoneNo : "";
    this.$getLocation({}).then((coordinates) => {
      this.coordinates = coordinates;
    });

    if (localStorage.getItem("role") == 3) {
      const b = await getKennelClubs();
      this.kennelClubsList = b.data;
    }
  },
  methods: {
    ...mapActions({
      getUser: "auth/getUser",
      updatePetOwner: "auth/updatePetOwner",
      updateDogBreeder: "auth/updateDogBreeder",
      updateGuest: "auth/updateGuest",
    }),
    // telValidate(telnumber) {
    //   this.editPhoneNumber = telnumber;
    // },

    onDateChange() {
      /* this.date_appointment = this.date_appointment;
      console.log(this.date_appointment); */
    },

    handleInput(value) {
      this.account.phoneNo = value.replace(" ", "");
    },
    countryChanged(country) {
      this.countryCode = country.dialCode;
    },
    async inputSelectCountry() {
      /* const a = await getStates(this.account.country.id);
      this.statesList = a.data;*/
    },

    validate() {
      console.log("role:", localStorage.getItem("role"));
      const errors = {};

      if (this.account.phoneNo === "")
        errors.phoneNo = "Phone number is required";

      if (this.account.firstName.trim() === "")
        errors.firstName = "FirstName  is required";
      if (this.account.lastName.trim() === "")
        errors.lastName = "LastName  is required";
      if (this.account.postalCode.trim() === "")
        errors.postalCode = "postal Code  is required";
      if (localStorage.getItem("role") == 1) {
        if (this.account.streetAddress.trim() === "")
          errors.streetAddress = "Address is required";
      }

      if (localStorage.getItem("role") == 3) {
        if (this.account.kennelAddress.trim() === "")
          errors.kennelAddress = "Address is required";
        if (this.account.kennelClub === "")
          errors.kennelClub = "Kennel Club is required";
        if (this.account.kennelName.trim() === "")
          errors.kennelName = "Kennel Registration is required";
        if (this.account.kennelRegistrationNo.trim() === "")
          errors.kennelRegistrationNo = this.$t(
            "after_verification_error_kannel_registration_number"
          );
      }

      return Object.keys(errors).length === 0 ? null : errors;
    },
    cancelForm(user) {
      const req = {
        email: localStorage.getItem("email"),
        role: localStorage.getItem("role"),
      };

      this.getUser(req);
      this.phone = user ? user.phoneNo : "";
    },

    async submitForm(image) {
      console.log("------", image);
      const errors = this.validate();
      this.errors = errors || {};
      if (errors) return;

      console.log(this.account);

      const req = {
        image: image,
        account: this.account,
        coordinates: this.coordinates,
      };

      if (localStorage.getItem("role") == 1) {
        console.log(this.role, "1");
        this.updatePetOwner(req);
      }
      if (localStorage.getItem("role") == 2) {
        console.log(this.role, "2");
        this.updateGuest(req);
      }
      if (localStorage.getItem("role") == 3) {
        console.log(this.role, "3");
        this.updateDogBreeder(req);
      }
    },
  },
};
</script>

<style>
.vti__dropdown {
  border: 1px solid #ccc;
  border-radius: 0.125rem 0 0 0.125rem;
}
.vue-tel-input {
  border-radius: 0.125rem;
  border: none;
  font-size: 0.875rem;
}
.vti__input {
  padding: 0.75rem 10px 0.75rem;
  border-radius: 0 0.125rem 0.125rem 0;
}
.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: none;
  outline: auto;
}
</style>
