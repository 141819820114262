<template>
  <div class="w-full ml-1 flex flex-col justify-start items-start">
    <label class="text-sm font-proximaMedium" for="name">{{
      label
    }}</label>
    <select
      name="name"
      id="name"
      required
      :value="value"
      :disabled="!editable"
      @input="$emit('update', $event.target.value)"
      class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
    >
      <option value="" disabled selected>{{$t('global_select_options')}}</option>
      <option v-for="data in dataList" :key="data.id" v-bind:value="data.id">
        {{ data.name }}
      </option>
    </select>
  </div>
</template>
  <script>
export default {
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    value: { type: String, default: "" },
    label: String,
    name: String,
    dataList: {
      type: [String, Array],
      default: function () {
        return ["Option 1", "Option 2"];
      },
    },
  },
  computed: {
    classNames() {
      let classNames = [];
      classNames.push(`${this.classAdd}`);
      return classNames;
    },
  },

  model: {
    prop: "value",
    event: "update",
  },
};
</script>